import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class VideoService{
	apiUrl = baseUrl

	async loadVideos (lastIndex) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-video/${lastIndex}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async submitNewVideo (postData) {
		const apiToken = storageService.getItem().token
		return await axios.post(`${baseUrl}/submit-video`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
	async loadSingleVideo (videoId) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-video-single/${videoId}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateVideo (postData, videoId) {
		const apiToken = storageService.getItem().token
		return await axios.put(`${baseUrl}/submit-video/${videoId}`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteVideo (videoId) {
		const apiToken = storageService.getItem().token
		return await axios.delete(`${baseUrl}/delete-video/${videoId}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}
const videoService = new VideoService()
export { videoService }