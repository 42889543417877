<template>
	<div class="">
		<form @submit.prevent="onSubmit" novalidate>
			<div class="row g-3">
				<div class="form-group mb-2">
					<label for="">Title</label>
					<input type="text" v-model="vv.title.$model" placeholder="Enter title" class="form-control">
					<span class="error">{{ vv?.title?.$errors[0]?.$message }}</span>
				</div>
			</div>
			<div class="row g-3">
				<div class="form-group mb-2">
					<label for="">Video Link</label>
					<p class="text-info" style="margin-top: 2px; margin-bottom: 2px;">Please the video link should be of this format <span class="font-italic text-danger">https://www.youtube.com/embed/RtJzK4EGFq8</span></p>
					<input type="text" v-model="vv.link.$model" placeholder="Enter link youtube" class="form-control">
					<span class="error">{{ vv?.link?.$errors[0]?.$message }}</span>
				</div>
			</div>
			<div class="row g-3">
				<div class="form-group mb-2">
					<label for="">Short note</label>
					<textarea class="form-control" v-model="vv.content.$model" placeholder="Enter short note" rows="2"></textarea>
					<span class="error">{{ vv?.content?.$errors[0]?.$message }}</span>
				</div>
			</div>
			<div class="form-group mb-3">
				<img class="image" id="previewImage" :src="setImage(selectedImage)" alt="">
				<label class="custom-file-upload">
					<input type="file" @change="handleFileChange" id="profile-image-input" accept="image/*"/>
					Add Video Cover Picutre
				</label>
			</div>
			<div class="form-group" style="width: 100%;">
				<button class="btn btn-primary btn-block" :disabled="vv.$invalid" v-if="editMode && !isProcessing">Update</button>
				<button class="btn btn-primary btn-block" :disabled="vv.$invalid" v-if="!editMode && !isProcessing">Save</button>
				<button class="btn btn-primary btn-block" :disabled="isProcessing" v-if="isProcessing">
					<span class="spinner-border spinner-border-sm mr-3 pr-3" role="status" aria-hidden="true"></span>Submitting Data
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { videoService } from '@/services/videos.service'
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";
export default {
	name: 'LectureNoteVideo',
	props: {
		editMode: Boolean,
		videoID: [Number, String]
	},
	setup () {
		const fform = reactive({
			title: '',
			content: '',
			link: '',
		})
		const rules = computed(() => (
		{
			title: { required },
			content: { required },
			link: { required, url }
		}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isProcessing: false,
			selectedImage: '',
			isImageChanged: false
		};
	},
	mounted() {
		if(this.editMode){
			this.loadContent()
		}
	},
	methods: {
		loadContent () {
			videoService.loadSingleVideo(this.videoID)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						const videoRow = response.data.videoRow
						this.fform.title = videoRow.title
						this.fform.link = videoRow.video_link
						this.fform.content = this.$generalHelper.decodeHtmlEntites(videoRow.content).replace(/(?:<br>)/g, '\n')
						this.selectedImage = videoRow.cover_image
					}else{
						this.$emit('errorClose', false)
					}
				})
				.catch((e) => {
					console.log(e)
					this.$emit('errorClose', false)
				})
		},
		setImage (image) {
			if(image.length === 0){
				return image
			}
			const index = image.indexOf('base64')
			if(index !== -1) {
				return image
			}else{
				return `${videoService.apiUrl}/video-image/${image}`
			}
		},
		async handleFileChange () {
			const elem = document.getElementById('profile-image-input')
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressServerImage(elem.files[0])
				this.selectedImage = image
				if(this.editMode){
					this.isImageChanged = true
				}
			}
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isProcessing = true
			if(!this.editMode){
				const postData = {
					title: this.fform.title,
					content: this.$generalHelper.encodeHtmlEntites(this.fform.content),
					link: this.fform.link,
					cover_image: this.selectedImage
				}
				videoService.submitNewVideo(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.$emit('closeForm', false)
						}else{
							this.$swal({
								icon: "error",
								text: "Error saving data"
							})
						}
					})
					.catch((e) => {
						this.isProcessing = false
						console.log(e)
						this.$swal({
							icon: "error",
							text: "Error saving data"
						})
					})
			}else{
				const postData = {
					title: this.fform.title,
					content: this.$generalHelper.encodeHtmlEntites(this.fform.content),
					link: this.fform.link,
					cover_image: this.selectedImage
				}
				videoService.updateVideo(postData, this.videoID)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.$emit('closeForm', true)
						}else{
							this.$swal({
								icon: "error",
								text: "Error saving data"
							})
						}
					})
					.catch((e) => {
						this.isProcessing = false
						console.log(e)
						this.$swal({
							icon: "error",
							text: "Error saving data"
						})
					})
				console.log('paulex')
			}
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: var(--paul-color-lfx-new);
	height: auto;
	color: #ffffff;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid var(--paul-color-light);
	background-color: var(--paul-color-light);
	margin-right: 20px;
}
</style>
