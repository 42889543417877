<template>
	<div class="container mt-4">
		<h2 class="label-header">Lecture Videos</h2>
		<button class="btn btn-primary btn-sm mb-3 mt-3" v-if="!openForm" @click.stop="toggleForm">Add New Video</button>
		<button class="btn btn-danger btn-sm mb-3 mt-3" v-if="openForm" @click.stop="toggleForm">Cancel</button>
		<div class="card mb-4" v-if="!openForm">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover table-striped table-bordered">
						<thead>
							<tr>
								<th>Title</th>
								<th>Link</th>
								<th width="100px">Action</th>
							</tr>
						</thead>
						<tbody v-if="isLoading">
							<tr>
								<td colspan="3">
									<div class="d-flex justify-content-center flex-column align-items-center">
										<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && videoList.length > 0">
							<tr v-for="(item, i) in videoList" :key="i">
								<td>
									{{ item.title }}
								</td>
								<td>
									<a :href="item.link" target="_blank" class="btn btn-sm btn-info text-white">Watch Video</a>
								</td>
								<td>
									<div class="btn-group">
											<button class="btn btn-sm btn-primary" style="margin-right: 10px;" @click="editInfo(item)">Edit</button>
											<button class="btn btn-sm btn-danger" style="margin-right: 10px;" @click="deleteInfo(item)">Delete</button>
										</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && videoList.length === 0">
							<tr>
								<td colspan="3">
									<div class="d-flex justify-content-center flex-column align-items-center">
										No Video have been uploaded yet
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="lastIndex !== 0">
					<button class="btn btn-sm btn-secondary" @click="loadPage()">Load More</button>
				</div>
			</div>
		</div>
		<div class="card" v-if="openForm">
			<div class="card-body">
				<lecture-note-video :editMode="editMode" @closeForm="closeInfoForm" @errorClose="closeFormOnError" :videoID="videoID" />
			</div>
		</div>
	</div>
</template>

<script>
import { videoService } from '@/services/videos.service'
import LectureNoteVideo from '@/components/LectureNoteVideo.vue'
export default {
	name: 'LectureVideo',
	components: {
		LectureNoteVideo
	},
	data() {
		return {
			isLoading: true,
			lastIndex: 0,
			openForm: false,
			editMode: false,
			videoList: [],
			errorLoad: false,
			videoID: null
		};
	},
	mounted() {
		this.loadPage()
	},
	methods: {
		loadPage () {
			videoService.loadVideos(this.lastIndex)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						for(const item of response.data.videoList){
							this.videoList.push(item)
						}
						this.lastIndex = response.data.lastIndex
						this.errorLoad = false
					}else{
						this.errorLoad = true
					}
				})
				.catch((e) => {
					this.isLoading = false
					this.errorLoad = true
					console.log(e)
				})
		},
		closeInfoForm (isEdit){
			this.openForm = false
			if(isEdit){
				this.editMode = false
				this.$swal("Data Saved Successful")
			}else{
				this.$swal("Data Saved Successful")
			}
			this.videoList = []
			this.lastIndex = 0
			this.loadPage()
		},
		closeFormOnError () {
			this.openForm = false
			this.editMode = false
			this.$swal({
				icon: "error",
				text: "Error occured try again later"
			})
		},
		toggleForm () {
			this.openForm = !this.openForm
			if(!this.openForm){
				this.editMode = false
			}
		},
		editInfo (item) {
			this.videoID = item.id
			this.editMode = true
			this.openForm = true
			console.log(item)
		},
		deleteInfo (item) {
			this.isLoading = true
			videoService.deleteVideo(item.id)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.$swal({
							icon: "success",
							text: "Data deleted"
						})
						this.videoList = []
						this.lastIndex = 0
						this.loadPage()
					}else{
						this.$swal({
							icon: "error",
							text: "Error deleting data"
						})
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
					this.$swal({
						icon: "error",
						text: "Error deleting data"
					})
				})
		}
	}
};
</script>

<style scoped>
</style>
